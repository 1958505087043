import { Random } from './random-generator';

export const Palettes = {
  WHITE: ['#fff'],
  GRAY: ['#BABCC1', '#B2B2B1', '#7A7B7C', '#51585C', '#444749'],
  BLUE: ['#6CACE4', '#0072CE', '#0032A0', '#002D72', '#0C2340'],
  RED: ['#A40900', '#950505', '#6D0000', '#D53426', '#931107'],
  PINK: ['#F5CEC8', '#F0ABAE', '#F492A2'],
  GREEN: ['#6DCDA9', '#97DBB1', '#CCF395', '#E1F6AD'],
  RAINBOW: [
    '#FCFCC2', '#FCF782', '#FCE200', '#FFC900',
    '#F79E00', '#EC6E37',
    '#F22F0B',
    '#FEE5E2', '#ECC8D4', '#EBAECD',
    '#823361', '#B86B98', '#775A8B',
    '#E1F6AD', '#CCF395', '#A9DE22', '#7CDC63',
    '#C7F9EF', '#A9E8DC', '#43D6E9', '#14ACD8', '#435AAC', '#2A3660',
  ],
  // This palette is a placeholder since it is dynamically constructed
  'RAINBOW DUOCHROME': [],
  // This palette is also dynamically constructed
  'RAINBOW MONOCHROME': [
    '#FEE5E2', '#FEE5E2', '#FCE200', '#F79E00', '#F22F0B', '#823361', '#B86B98',
    '#7CDC63', '#2A3660', '#435AAC', '#14ACD8',
  ],
  'RED WHITE BLUE': ['#B42033', '#fff', '#3C3B6E'],
}

export const StripePalettes = {
  BLACK: ['#000'],
  WHITE: ['#fff'],
  'BLACK & WHITE': ['#000', '#fff'],
}

export type Orientation = 'SQUARE' | 'PORTRAIT' | 'LANDSCAPE';
export type PanelSize = 'JUMBO' | 'LARGE' | 'MEDIUM' | 'SMALL' | 'TINY' | 'MICRO';

export const ImperfectionLevel = {
  SLIGHT: 0.0025,
  NOTICEABLE: 0.005,
  INTENSE: 0.01,
}

export const getPaletteColours = (random: Random, paletteName: keyof typeof Palettes) => {
  if (paletteName === 'RAINBOW MONOCHROME') return [random.random_choice(Palettes[paletteName])];
  if (paletteName === 'RAINBOW DUOCHROME') return random.shuffle(Palettes['RAINBOW']).slice(0, 2);

  return Palettes[paletteName];
}

export interface Attributes {
  columns: number;
  rows: number;
  orientation: Orientation,
  panelSize: PanelSize,
  imperfectionLevel: keyof typeof ImperfectionLevel;
  palette: keyof typeof Palettes;
  paletteColours: string[];
  stripePalette: keyof typeof StripePalettes;
}

export const generateAttributes = (random: Random): Attributes => {
  const palette = random.pick_weighted(probabilities.palettes);
  const paletteColours = getPaletteColours(random, palette);
  const stripePalette = pickStripePalette(random, palette);
  const imperfectionLevel = random.pick_weighted(probabilities.imperfectionLevels);

  const panelSize = random.pick_weighted(probabilities.panelSize);
  const orientation = panelSize === 'JUMBO'
    ? random.pick_weighted(probabilities.orientation.filter(([ratio]) => ratio !== 'SQUARE'))
    : random.pick_weighted(probabilities.orientation);
  const [columns, rows] = getDimensions(random, orientation, panelSize);

  const attributes = { columns, rows, orientation, panelSize, imperfectionLevel, palette, stripePalette, paletteColours };

  return attributes;
}

const pickStripePalette = (random: Random, palette: keyof typeof Palettes): keyof typeof StripePalettes => {
  const fallback: [keyof typeof StripePalettes, number][] = [['WHITE', 1], ['BLACK & WHITE', 1]];
  const allStripePalettesWeighted: [keyof typeof StripePalettes, number][] = [['BLACK', 30], ['WHITE', 30], ['BLACK & WHITE', 40]];

  const mapping: { [index: string]: [keyof typeof StripePalettes, number][] } = {
    WHITE: [
      ['BLACK', 37],
      ['WHITE', 26],
      ['BLACK & WHITE', 37],
    ],
    GRAY: allStripePalettesWeighted,
    BLUE: allStripePalettesWeighted,
    RED: allStripePalettesWeighted,
    RAINBOW: [
      ['BLACK & WHITE', 1],
    ],
  }

  return random.pick_weighted(mapping[palette] ?? fallback);
}

const getDimensions = (random: Random, orientation: Orientation, panelSize: PanelSize) => {
  const mapping = {
    // JUMBO does not work with SQUARE, so these values are placeholders so that
    // the calculation produces 4,3 and 3,3 for PORTRAIT and 5,3 for LANDSCAPE
    JUMBO: [5, 3],
    LARGE: [6, 4],
    MEDIUM: [9, 6],
    SMALL: [12, 8],
    TINY: [18, 12],
    MICRO: [30, 20],
  }

  if (orientation === 'LANDSCAPE') {
    const [columns, squareRows] = mapping[panelSize];
    const rows = changeDimension(random, squareRows, 0.6, 0.8);
    return [columns, rows] as [number, number];
  }

  if (orientation === 'PORTRAIT') {
    const [squareColumns, rows] = mapping[panelSize];
    const columns = changeDimension(random, squareColumns, 0.6, 0.8);
    return [columns, rows] as [number, number];
  }

  return mapping[panelSize] as [number, number];
}

const changeDimension = (random: Random, squareDimension: number, minMultiplier: number, maxMultiplier: number) => {
  const minDimension = Math.max(Math.round(squareDimension * minMultiplier), 3);
  const maxDimension = Math.max(Math.round(squareDimension * maxMultiplier), 3);
  const dimension = Math.round(random.random_num(minDimension, maxDimension));
  return dimension;
}

const probabilities = {
  orientation: [
    ['SQUARE', 30],
    ['PORTRAIT', 35],
    ['LANDSCAPE', 35],
  ] as [Orientation, number][],
  panelSize: [
    ['JUMBO', 13],
    ['LARGE', 23],
    ['MEDIUM', 34],
    ['SMALL', 20],
    ['TINY', 8],
    ['MICRO', 2],
  ] as [PanelSize, number][],
  imperfectionLevels: [
    ['SLIGHT', 1],
    ['NOTICEABLE', 1],
    ['INTENSE', 1],
  ] as [keyof typeof ImperfectionLevel, number][],
  palettes: [
    ['WHITE', 5],
    ['GRAY', 5],
    ['RED WHITE BLUE', 5],
    ['PINK', 8],
    ['GREEN', 8],
    ['BLUE', 8],
    ['RED', 8],
    ['RAINBOW MONOCHROME', 10],
    ['RAINBOW DUOCHROME', 21],
    ['RAINBOW', 22],
  ] as [keyof typeof Palettes, number][]
}
