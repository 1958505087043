import { useState } from 'react';
import Artwork from './Artwork';
import './App.css';
import { genTokenData } from './common/utils';

function App() {
  const [tokenData, setTokenData] = useState(genTokenData(176));

  const generate = () => {
    setTokenData(genTokenData(176));
  }

  return (
    <div className="App">
      <h2>Imperfections by Kalis</h2>
      <Artwork tokenData={tokenData} canvasSize={800} drawAttributes />
      <button style={{ margin: '20px' }} onClick={generate}>Generate</button>
      <Artwork tokenData={tokenData} canvasSize={250} />
      <div style={{ margin: 'auto', marginBottom: '100px', width: '800px' }}>
        <h2>Attributes</h2>
        <h3>Panel size</h3>
        <p>
          Panel size indicates the size of the individual panels in the artwork. Smaller panels automatically
          corresponds to more panels, since the total size of the composition stays roughly the same. The most common
          size is MEDIUM, with both the smaller and the bigger sizes becoming progressively rarer.
        </p>
        <h3>Orientation</h3>
        <p>
          Orientation can be PORTRAIT, LANDSCAPE or SQUARE, with an even distribution between them.
        </p>
        <h3>Palette</h3>
        <p>
          Palettes indicate which colors and color distributions are used by the panels in the artwork.
          The most colorful palettes are the most common (e.g. RAINBOW / RAINBOW DUOCHROME), the monochrome "gradient" palettes
          (e.g. BLUE / PINK) are less common, and the GRAY / WHITE palettes are the rarest.
        </p>
        <h3>Stripe Palette</h3>
        <p>
          Stripe Palettes indicate which colors are used by the stripes in the artwork. This is most commonly
          both BLACK & WHITE, but in less common cases they can be only BLACK or only WHITE.
        </p>
        <h3>Imperfections</h3>
        <p>
          The imperfections attribute indicates the level of imperfections in the artwork.
          There is an even distribution between the different levels of imperfections.
        </p>
      </div>
    </div>
  );

}

export default App;
