import { Random } from './random-generator';

export const imperfectNumber = (random: Random, num: number, maxDiff: number) => {
  return num + random.random_num(-maxDiff, +maxDiff);
}

export const calculatePanelWidth = (canvasSize: number, columns: number, rows: number, gap: number, padding: number) => {
  const drawableCanvasWidth = canvasSize - padding * 2 - gap * (columns - 1);
  const maxPanelWidth = drawableCanvasWidth / columns;

  const drawableCanvasHeight = canvasSize - padding * 2 - gap * (rows - 1);
  const maxPanelHeight = drawableCanvasHeight / rows;

  const panelWidth = Math.floor(Math.min(maxPanelWidth, maxPanelHeight / 1.5));

  return panelWidth;
}

export const genTokenData = (projectNum: number) => {
  let hash = "0x";
  for (var i = 0; i < 64; i++) {
    hash += Math.floor(Math.random() * 16).toString(16);
  }

  const tokenId = (projectNum * 1000000 + Math.floor(Math.random() * 1000)).toString();

  return { hash, tokenId };
}
