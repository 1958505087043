import { calculatePanelWidth, imperfectNumber } from './utils';
import { Attributes, ImperfectionLevel, StripePalettes } from './attributes';
import { Random } from './random-generator';

type P5 = import('p5');

const MIN_PADDING_PERCENTAGE = 0.1;
const GAP_PERCENTAGE = 0.005;
const MIN_GAP = 0;

export const draw = (p5: P5, random: Random, attributes: Attributes, canvasSize: number) => {
  p5.clear();
  p5.background('#f5f3f6');

  const { columns, rows } = attributes;

  const panelWidth = calculatePanelWidth(canvasSize, columns, rows, MIN_GAP, MIN_PADDING_PERCENTAGE * canvasSize);
  const panelHeight = Math.round(panelWidth * 1.5);

  const gap = Math.max(GAP_PERCENTAGE * panelWidth, MIN_GAP);

  const horizontalPadding = (canvasSize - (columns * panelWidth + (columns - 1) * gap)) / 2;
  const verticalPadding = (canvasSize - (rows * panelHeight + (rows - 1) * gap)) / 2;

  for (let x = horizontalPadding; x < p5.width - horizontalPadding; x += (panelWidth + gap)) {
    for (let y = verticalPadding; y < p5.height - verticalPadding; y += (panelHeight + gap)) {
      drawPanel(p5, random, x, y, panelWidth, panelHeight, attributes);
    }
  }
};

const drawPanel = (p5: P5, random: Random, x: number, y: number, width: number, height: number, attributes: Attributes) => {
  // max Y diff is uncapped
  const maxDiffY = ImperfectionLevel[attributes.imperfectionLevel] * height;

  // max X diff is capped at SLIGHT imperfections
  const maxDiffX = Math.min(height * ImperfectionLevel.SLIGHT, maxDiffY);

  // max panel diffs is also capped at SLIGHT imperfections
  const maxDiffPanel = maxDiffX;

  const panelColour = random.random_choice(attributes.paletteColours);
  const strokeColour = '#000';
  const panelStrokeWeight = 0.001 * height;
  const stripeStrokeWeight = 2 * panelStrokeWeight;

  drawImperfectRect(p5, random, x, y, width, height, maxDiffPanel, maxDiffPanel, strokeColour, panelColour, panelStrokeWeight);

  const stripePalette = StripePalettes[attributes.stripePalette];

  const stripeHeights = [0.06, 0.09, 0.12].map((percentage) => percentage * height);
  const gapSizes = [0.09, 0.12, 0.15, 0.18, 0.21, 0.24, 0.27].map((percentage) => percentage * height);;

  const start = random.random_choice([0, 0.03, 0.06, 0.09].map((percentage) => percentage * height));

  let endOfPreviousStripe = -1;
  for (let currentHeight = start; currentHeight < height; currentHeight += random.random_choice(gapSizes)) {
    const fillColour = random.random_choice(stripePalette);

    const stripeHeight = Math.min(random.random_choice(stripeHeights), (y + height) - (y + currentHeight));
    if (stripeHeight < Math.min(...stripeHeights)) break;

    // Don't draw the top stroke of the stripe when stripes are overlapping
    const drawTop = endOfPreviousStripe < currentHeight;
    drawImperfectRect(p5, random, x, y + currentHeight, width, stripeHeight, maxDiffX, maxDiffY, strokeColour, fillColour, stripeStrokeWeight, drawTop);

    endOfPreviousStripe = currentHeight + stripeHeight;
  }
}

const drawImperfectRect = (
  p5: P5,
  random: Random,
  x: number, y: number,
  width: number, height: number,
  maxDiffX: number, maxDiffY: number,
  strokeColour: string, fillColour: string,
  baseStrokeWeight: number, drawTop?: boolean,
) => {
  const x1 = imperfectNumber(random, x, maxDiffX);
  const y1 = imperfectNumber(random, y, maxDiffY);

  const x2 = imperfectNumber(random, x, maxDiffX);
  const y2 = imperfectNumber(random, y + height, maxDiffY);

  const x3 = imperfectNumber(random, x + width, maxDiffX);
  const y3 = imperfectNumber(random, y + height, maxDiffY);

  const x4 = imperfectNumber(random, x + width, maxDiffX);
  const y4 = imperfectNumber(random, y, maxDiffY);

  p5.fill(fillColour);
  p5.stroke(strokeColour);

  p5.strokeWeight(baseStrokeWeight);
  p5.beginShape();
  p5.vertex(x1, y1);
  p5.vertex(x2, y2);
  p5.vertex(x3, y3);
  p5.vertex(x4, y4);
  p5.endShape();

  if (drawTop) {
    p5.strokeWeight(baseStrokeWeight * 0.3);
    p5.beginShape();
    p5.vertex(x4, y4);
    p5.vertex(x1, y1);
    p5.endShape();
  }
}

export const displayAttributes = (p5: P5, attributes: Attributes) => {
  p5.fill('black');
  p5.noStroke();
  p5.textSize(12);
  p5.text(`Dimensions: ${attributes.columns} x ${attributes.rows}`, 10, 12);
  p5.text(`Panel size: ${attributes.panelSize}`, 10, 24);
  p5.text(`Orientation: ${attributes.orientation}`, 10, 36);
  p5.text(`Palette: ${attributes.palette}`, 10, 48);
  p5.text(`Stripe Palette: ${attributes.stripePalette}`, 10, 60);
  p5.text(`Imperfections: ${attributes.imperfectionLevel}`, 10, 72);
}
